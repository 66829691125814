import { useContext, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import sanitize from 'sanitize-html';

import { Col, Row } from 'react-bootstrap';

import RestaurantContext from '../Context/RestaurantContext';

const Wrapper = styled(Row)`
  padding-top: 75px;

  @media (max-width: 767.97px) {
    flex-direction: column-reverse;
    padding-top: 35px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  min-height: 280px;

  @media (max-width: 767.97px) {
    min-height: 200px;
    width: 100%;
  }
`;

const AboutUsImageCol = styled(Col)`
  @media (max-width: 767.97px) {
    padding: 0px;
  }
`;

const TextWrapper = styled.div`
  font-size: 15px;
  line-height: initial;
  margin: 0px 36px;
  word-break: break-word;

  & h3 {
    color: RGB(72, 79, 81);
  }

  & h2 {
    margin-bottom: 20px;

    @media (max-width: 767.97px) {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 767.97px) {
    margin: 0px;
  }
`;

const StyledImage = styled(Image)`
  border-radius: 10px;

  @media (max-width: 575.97px) {
    border-radius: 0px;
  }
`;

function AboutUsSection() {
  const { restaurantProps: storeProps } = useContext(RestaurantContext);
  const [cleanText] = useState(
    sanitize(storeProps.aboutUs.text, {
      allowedTags: [
        'strong',
        'em',
        'li',
        'ol',
        'ul',
        'i',
        'blockquote',
        'span',
        'small',
        'sub',
        'sup',
        'a',
        'p',
      ],
    }),
  );

  return (
    <>
      <Wrapper>
        <AboutUsImageCol xs={12} md={6}>
          <ImageWrapper>
            <StyledImage
              alt="Über Uns"
              src={storeProps.aboutUs.imageCdnPath}
              layout="fill"
              objectFit="cover"
            />
          </ImageWrapper>
        </AboutUsImageCol>
        <Col xs={12} md={6}>
          <TextWrapper>
            <div className="text-center">
              <h3>{storeProps.aboutUs.subtitle}</h3>
              <h2>{storeProps.aboutUs.title}</h2>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: cleanText.replace(/\n/g, '<br />'),
              }}
            ></p>
          </TextWrapper>
        </Col>
      </Wrapper>
    </>
  );
}

export default AboutUsSection;
