import React, { useRef, useContext, useState, useEffect } from 'react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import hash from 'object-hash';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Container, Row, Col, Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import Icon from '../components/Icon';
import ContactDetailComponent from '../components/ContactDetailComponent';
import DeliveryRegionListEntry from '../components/DeliveryRegionListEntry';

import OpeningTimesList from '../components/OpeningTimesList';
import IconsLine from '../components/IconsLine';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useInView } from 'react-cool-inview';
import rewriteAssetPath from '../util/rewriteAssetPath';
import AboutUsSection from '../components/AboutUsSection';

import RegionSelectorModal from '../components/RegionSelectorModal';
import { toast } from 'react-toastify';

import RestaurantContext from '../Context/RestaurantContext';

// dynmic import frontende deps which rely on e.g. window
import LogoHeader from '../components/LogoHeader';
import { Currency } from 'react-intl-number-format';
const GMapsComponent = React.lazy(() => import('../components/Maps/GMapComponent'));

const HeroElement = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 630px;
  background: #ffffff;
  overflow: hidden;

  clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 65px), 0% 100%);
`;

const ZipBoxWrapper = styled.div`
  position: absolute;
  display: flex;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const ZipBox = styled.div`
  position: relative;
  display: block;
  min-height: 160px;
  flex-grow: 1;
  max-width: 660px;

  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(120, 130, 140, 0.13);
  // overflow: hidden;

  h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;
    white-space: nowrap;
    @media (min-width: 768px) {
      font-size: 27px;
      margin-bottom: 30px;
    }
  }

  #button-addon2 {
    padding: 0 10px;
    font-size: 17px;
    font-weight: 400;
    min-width: 150px;
    letter-spacing: 0.75px;

    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }

  @media (max-width: 767.98px) {
    .zipLabel {
      white-space: nowrap;
      font-size: 15px;
      line-height: 9px;
    }

    .zip {
      height: 40px;
      padding: 10px;
      font-size: 15px;
      padding-top: 1.25rem;
    }
    .form-floating > .form-control:focus ~ .zipLabel,
    .form-floating > .form-control:not(:placeholder-shown) ~ .zipLabel,
    .form-floating > .form-select ~ .zipLabel {
      transform: scale(0.75) translateY(-0.5rem) translateX(0.1rem); // none !important;
    }
  }

  @media (min-width: 768px) {
    width: 660px;

    #button-addon2 {
      padding: 0 30px;
    }
  }
`;

const ZipBoxInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;

  padding: 20px 15px 0 15px;
  @media (min-width: 768px) {
    padding: 40px 55px 0 55px;
    height: 100%;
    min-height: inherit;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }

  will-change: transform, opacity;

  #btn-onlyPickUp-order {
    flex-grow: 1;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.75px;

    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }
`;

const PaymentTypesWrapper = styled.div`
  height: 15px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  opacity: 0.65;

  .pt {
    height: 15px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  #pt-visa.pt {
    height: 13px;
  }
  #pt-master.pt {
    height: 20px;
  }

  @media (min-width: 768px) {
    height: 20px;
    margin-bottom: 40px;

    .pt {
      height: 20px;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    #pt-visa.pt {
      height: 18px;
    }
    #pt-master.pt {
      height: 22px;
    }
  }
`;

const RestaurantStatusWrapper = styled.div`
  display: block;
  position: relative;
  bottom: 0;
  color: #ffffff;
  text-align: center;

  background-color: #ec971f;
  z-index: 2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  p {
    margin: 0;
    line-height: 19px;
    font-size: 15px;
    letter-spacing: 0.67px;
    @media (min-width: 768px) {
      line-height: 25px;
      font-size: 19px;
      letter-spacing: 0.84px;
    }
  }

  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 55px;
    padding-right: 55px;

    margin-left: -55px;
    margin-right: -55px;
    margin-bottom: 0;
  }
`;

const Teaser = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  padding-top: 35px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    padding-top: 75px;
    margin-bottom: 75px;
  }
`;

const StepsRow = styled(Row)`
  display: flex;

  margin-bottom: 35px;
  @media (min-width: 768px) {
    margin-bottom: 75px;
  }

  & .step:not(:last-child) {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: 0.71px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-top: 15px;
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }

  span {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.71px;

    @media (min-width: 768px) {
      font-size: 19px;
      letter-spacing: 0.84px;
    }
  }

  #position {
    height: 67px;
  }
  #foodmenu {
    height: 70px;
  }
  #scooter-min {
    height: 56px;
  }
  #store {
    height: 70px;
  }
  #menu {
    height: 67px;
  }

  @media (min-width: 768px) {
    #position,
    #foodmenu,
    #scooter-min,
    #store,
    #menu {
      height: 90px;
    }
  }
`;

const ButtonRow = styled(Row)`
  text-align: center;
  padding-bottom: 35px;

  @media (min-width: 768px) {
    padding-bottom: 75px;
  }
`;

const GrayBg = styled.div`
  background-color: #f3f3f3;
`;
const WhiteBg = styled.div`
  background-color: #ffffff;
`;

const DeliveryRegionsRow = styled(Row)`
  margin-top: 35px;

  @media (min-width: 768px) {
    margin-top: 75px;
  }

  @media (max-width: 767.98px) {
    #deliveryRegionImageCol {
      margin-top: -50px;
    }
  }
`;

const OpeningHoursRow = styled(Row)`
  margin-top: 40px;
  padding-bottom: 35px;
  @media (min-width: 768px) {
    margin-top: 75px;
    padding-bottom: 75px;
  }
`;

const DeliveryRegionImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const DeliveryRegionListCol = styled(Col)`
  @media (max-width: 767.98px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const OpeningTimesListCol = styled(Col)`
  text-align: center;
  @media (max-width: 767.98px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const RadialBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-height: 330px;
  @media (min-width: 992px) {
    max-height: 430px;
  }
  @media (min-width: 1200px) {
    max-height: 530px;
  }

  background: rgb(0, 0, 0);
  background: -moz-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
`;

const TitleImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

const OpeningSignCol = styled(Col)`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const OpeningSignWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  height: 100px;
  @media (min-width: 768px) {
    height: 225px;
  }
`;

const OrderWithUsRow = styled(Row)`
  position: relative;

  margin-top: 0;
  padding-top: 45px;
  padding-bottom: 35px;
  @media (min-width: 768px) {
    margin-top: 15px;
    padding-top: 100px;
    padding-bottom: 55px;
  }

  h2 {
    margin-bottom: 30px;
    font-size: 16px;
    @media (min-width: 768px) {
      margin-bottom: 30px;
      font-size: 21px;
    }
  }
`;

const StyledIconsLine = styled(IconsLine)`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;

  transform: translateY(-90%);
  height: 20px;
  @media (min-width: 768px) {
    height: 30px;
    transform: translateY(-90%);
  }
`;

const OrderWithUsTextWrapper = styled.div`
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 775px;

  font-size: 15px;
  letter-spacing: 0.67px;
  @media (min-width: 768px) {
    text-align: center;
    font-size: 17px;
    letter-spacing: 0.75px;
  }
`;

const MapAndContactRow = styled(Row)`
  margin-top: 45px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  h2 {
    margin-bottom: 25px;
  }
`;

const ContactCol = styled(Col)`
  margin-bottom: 35px;
`;

const MapCol = styled(Col)`
  padding: 0;
`;

const MapWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 280px;

  overflow: hidden;

  @media (min-width: 768px) {
    border-radius: 10px;
  }
`;

const FreeDeliveryFrom = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;
const FreeDeliveryFromButton = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 38px;
  border-radius: 19px;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.primary};
  text-align: center;
  justify-content: center;
  align-items: center;

  & > h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: white;
  }
`;

function Home(props) {
  const {
    restaurantProps: storeProps,
    getOverallStatus,
    ...restaurantContext
  } = useContext(RestaurantContext);
  const zipRef = useRef(null);
  const [selectionModalState, setSelectionModalState] = useState(() => ({
    show: false,
    regionList: [],
  }));

  const [toastOpened, setToastOpened] = useState(false);

  /**
   * lazy load map only when in view
   */
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  });

  const { push } = useRouter();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ zip }) => {
    const matchingRegionsList = [];
    // get all deliveryregions containing this zip
    storeProps.deliveryRegionsRaw?.forEach((deliveryRegion) => {
      if (deliveryRegion.zipCodes?.includes(zip)) {
        matchingRegionsList.push(deliveryRegion);
      }
    });

    if (matchingRegionsList.length > 1) {
      // show selectionModal with fetched data amk
      setSelectionModalState((state) => ({
        ...state,
        show: true,
        regionList: matchingRegionsList,
        forZip: zip,
      }));
    } else if (matchingRegionsList.length === 1) {
      // store selection in context and send to menu page
      onRegionSelected(matchingRegionsList[0], zip);
    } else {
      // show error to user
      if (!toastOpened) {
        toast.error(`Die PLZ ${zip} liegt leider außerhalb unseres Liefergebietes.`, {
          theme: 'colored',
          onOpen: () => setToastOpened(true),
          onClose: () => setToastOpened(false),
        });
      }
      setError(
        'zip',
        { type: 'noRegion', message: 'PLZ wird nicht beliefert' },
        { shouldFocus: true },
      );
    }
  };

  const onRegionSelected = (region, fromZip, fromModal = false) => {
    // close modal if was shown
    if (fromModal) hideRegionModal();

    // save region selection to context
    restaurantContext.setSelectedDeliveryRegion(region, fromZip);

    // redirect to menupage
    push('/menu');
  };

  const hideRegionModal = () => {
    setSelectionModalState((state) => ({ ...state, show: false }));
  };

  const hasErrors = () => {
    return errors?.zip && true;
  };

  const { ref: zipFormRef, ...zipRest } = register('zip', {
    required: { value: true, message: 'PLZ benötigt' },
    maxLength: { value: 5, message: 'PLZ ungültig' },
    minLength: { value: 5, message: 'PLZ ungültig' },
    pattern: { value: /^[0-9]{1,5}$/, message: 'PLZ ungültig' },
  });
  const getErrorMessage = (field) => {
    return field?.message || 'undefined';
  };

  const getRestaurantStatusText = ({ status, payload, groupStatus, ...statusData }) => {
    let heading = '';
    let subtitle = '';

    switch (getOverallStatus(storeProps)) {
      case 'CLOSED':
        heading = 'Restaurant aktuell geschlossen';
        subtitle = 'Besuchen Sie uns gerne wieder zu unseren Öffnungszeiten';
        break;
      case 'WILL_OPEN':
        heading = 'Restaurant noch geschlossen';
        subtitle = `Wir öffnen um ${moment(payload.firstMoment)
          .locale('de')
          .format('LT')} Uhr. Vorbestellungen möglich.`;
        break;
      case 'INACTIVE':
        heading = 'Lieferdienst kurzzeitig deaktiviert';
        subtitle = 'Bitte versuchen Sie es später noch einmal';
        break;
      case 'HOLIDAYS':
        heading = 'Wir sind aktuell im Urlaub';
        const holidayTo = moment(payload.dateTo).add(1, 'day').locale('de').format('L');
        subtitle = `Ab dem ${holidayTo} sind wir wieder zurück`;
        break;
      case 'OFFLINE':
        heading = 'Restaurant aktuell offline';
        subtitle = 'Bitte versuchen Sie es später noch einmal';
        break;
    }

    return (
      <>
        <p>
          <strong>{heading}</strong>
        </p>
        <p>{subtitle}</p>
      </>
    );
  };

  const getPaymentTypeIcon = (pt) => {
    switch (pt?.toUpperCase()) {
      case 'CASH':
        return <Icon key="cash" name="pt-cash" className="pt" />;
      case 'ECCASH':
        return <Icon key="eccash" name="pt-card" className="pt" />;
      case 'PAYPAL':
        return (
          <React.Fragment key="paypal">
            <Icon name="pt-paypal" className="pt" />
            <Icon name="pt-visa" id="pt-visa" className="pt" />
            <Icon name="pt-amex" className="pt" />
            <Icon name="pt-master" id="pt-master" className="pt" />
          </React.Fragment>
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (restaurantContext.selectedDeliveryRegion?.fromZip) {
      // set zip if there is already one
      setValue('zip', restaurantContext.selectedDeliveryRegion.fromZip, { shouldValidate: true });
    }
  }, [restaurantContext.selectedDeliveryRegion, setValue]);

  return (
    <>
      <GrayBg>
        <HeroElement>
          <TitleImageWrapper>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <Image
                priority
                src={rewriteAssetPath(storeProps.titleImagePath, props.env.APP_ENV)}
                title="Title Hero Image"
                alt="Title Hero Image"
                layout="fill"
              />
            </div>
          </TitleImageWrapper>

          <LogoHeader />

          <ZipBoxWrapper>
            <ZipBox>
              <ZipBoxInnerContainer>
                {!storeProps.onlyPickUp ? (
                  <>
                    <h1>Essen bestellen &amp; liefern lassen</h1>
                    <FormWrapper>
                      <Form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)} noValidate>
                        <InputGroup style={{ flexWrap: 'nowrap' }}>
                          <div className="form-floating" style={{ flexGrow: 1 }}>
                            <FormControl
                              ref={(e) => {
                                zipFormRef(e);
                                zipRef.current = e;
                              }}
                              isInvalid={hasErrors()}
                              name="zip"
                              id="zip-hero"
                              size="lg"
                              maxLength={5}
                              aria-label="PLZ eingeben"
                              className="zip"
                              placeholder="PLZ eingeben"
                              type="tel"
                              style={{ minHeight: '44px' }}
                              {...zipRest}
                            />
                            {hasErrors() ? (
                              <label
                                className="zipLabel"
                                htmlFor="zip-hero"
                                style={{ color: 'var(--bs-warning)' }}
                              >
                                {getErrorMessage(errors.zip)}
                              </label>
                            ) : (
                              <label className="zipLabel" htmlFor="zip">
                                PLZ eingeben
                              </label>
                            )}
                          </div>
                          <Button variant="primary" type="submit" id="button-addon2">
                            Jetzt bestellen
                          </Button>
                        </InputGroup>
                      </Form>
                    </FormWrapper>
                  </>
                ) : (
                  <>
                    <h1>Jetzt zur Abholung bestellen!</h1>
                    <FormWrapper>
                      <Button
                        variant="primary"
                        type="button"
                        size="lg"
                        block="true"
                        id="btn-onlyPickUp-order"
                        onClick={() => push('/menu')}
                      >
                        Zur Speisekarte
                      </Button>
                    </FormWrapper>
                  </>
                )}
                {getOverallStatus(storeProps) === 'OPENED' ? (
                  <PaymentTypesWrapper>
                    {storeProps.paymentTypes.map(getPaymentTypeIcon)}
                  </PaymentTypesWrapper>
                ) : (
                  <RestaurantStatusWrapper>
                    {getRestaurantStatusText(storeProps.statusData)}
                  </RestaurantStatusWrapper>
                )}
              </ZipBoxInnerContainer>
            </ZipBox>
          </ZipBoxWrapper>
        </HeroElement>
      </GrayBg>

      <GrayBg>
        <Container>
          <Teaser>
            <h3>Willkommen bei {storeProps.restaurantName}</h3>
            <h2>Bestellen kann so einfach sein</h2>
          </Teaser>
          {storeProps.onlyPickUp ? (
            <StepsRow>
              <Col xs={12} md={4} className="text-center step">
                <Icon id="menu" name="menu" />
                <h2>1. Gehe zur Speisekarte</h2>
                <span>und stöbere in unseren Köstlichkeiten</span>
              </Col>

              <Col xs={12} md={4} className="text-center step">
                <Icon id="foodmenu" name="foodmenu" />
                <h2>2. Wähle Dein Wunschessen</h2>
                <span>aus einer Vielzahl von Möglichkeiten</span>
              </Col>

              <Col xs={12} md={4} className="text-center step">
                <Icon id="store" name="store" />
                <h2>3. Schließe die Bestellung ab</h2>
                <span>und hole Dein Essen bem Restaurant ab</span>
              </Col>
            </StepsRow>
          ) : (
            <StepsRow>
              <Col xs={12} md={4} className="text-center step">
                <Icon id="position" name="position" />
                <h2>1. Gib Deine Postleitzahl ein</h2>
                <span>oder gehe über die Standortsuche</span>
              </Col>

              <Col xs={12} md={4} className="text-center step">
                <Icon id="foodmenu" name="foodmenu" />
                <h2>2. Wähle Dein Wunschessen</h2>
                <span>aus einer Vielzahl von Möglichkeiten</span>
              </Col>

              <Col xs={12} md={4} className="text-center step">
                <Icon id="scooter-min" name="scooter-min" />
                <h2>3. Schließe die Bestellung ab</h2>
                <span>und freue Dich auf leckeres Essen</span>
              </Col>
            </StepsRow>
          )}
          <ButtonRow>
            <Col xs={12}>
              <Button
                color="primary"
                size="lg"
                style={{
                  position: 'relative',
                  fontSize: '17px',
                  paddingLeft: 40,
                  paddingRight: 40,
                  letterSpacing: '1.2px',
                  height: 50,
                }}
                onClick={() => {
                  if (storeProps.onlyPickUp) return push('/menu');
                  window.scrollTo({
                    top: zipRef.current.offsetTop,
                    behavior: 'smooth',
                  });
                  zipRef.current.focus({ behavior: 'smooth' });
                }}
              >
                Jetzt bestellen
              </Button>
            </Col>
          </ButtonRow>
        </Container>
      </GrayBg>

      <WhiteBg>
        <Container>
          {storeProps.aboutUs?.subtitle && <AboutUsSection />}
          {!storeProps.onlyPickUp && (
            <DeliveryRegionsRow>
              <Col xs={12} md={6} className="text-center">
                <div style={{ marginBottom: '20px' }}>
                  <h3>Essen bestellen in Deiner Region</h3>
                  <h2>Unsere Liefergebiete</h2>
                </div>
              </Col>
              <Col md={6} className="d-none d-sm-block">
                &nbsp;
              </Col>
              <Col
                xs={12}
                md={6}
                style={{ position: 'relative', minHeight: '300px' }}
                className="order-md-2"
                id="deliveryRegionImageCol"
              >
                <DeliveryRegionImageWrapper>
                  <RadialBackground />
                  <Image
                    src="/svg/deliveryMap.svg"
                    layout="fill"
                    objectPosition="top"
                    objectFit="contain"
                    alt="delivery illustration"
                    title="delivery illustration"
                  />
                </DeliveryRegionImageWrapper>
              </Col>

              <DeliveryRegionListCol xs={12} md={6} className="text-center order-md-1">
                <div>
                  {storeProps.deliveryRegions.map((region) => (
                    <DeliveryRegionListEntry
                      key={hash(region)}
                      minOrderValueFrom={region.minOrderPriceFrom}
                      deliveryPriceFrom={region.deliveryPriceFrom || 0}
                      zipList={region.zipList}
                      cityNameList={region.cityNameList}
                    />
                  ))}
                </div>
                {storeProps.freeDeliveryFrom > 0 && (
                  <FreeDeliveryFrom>
                    <FreeDeliveryFromButton>
                      <h3>
                        Gratis Lieferung ab{' '}
                        {storeProps.freeDeliveryFrom % 1 === 0 ? (
                          <span>{storeProps.freeDeliveryFrom} €</span>
                        ) : (
                          <Currency as="span">{storeProps.freeDeliveryFrom}</Currency>
                        )}
                      </h3>
                    </FreeDeliveryFromButton>
                  </FreeDeliveryFrom>
                )}
              </DeliveryRegionListCol>
            </DeliveryRegionsRow>
          )}

          <OpeningHoursRow>
            <Col xs={12}>
              {storeProps.onlyPickUp ? (
                <Row>
                  <Col xs={12} md={6} className="text-center">
                    <div style={{ marginBottom: '20px' }}>
                      <h3>Jetzt vorbestellen und einfach abholen</h3>
                      <h2>Abholzeiten {storeProps.restaurantName}</h2>
                    </div>
                  </Col>
                  <Col md={6} className="d-none d-sm-block">
                    &nbsp;
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} md={6} className="text-center offset-md-6">
                    <div style={{ marginBottom: '20px' }}>
                      <h3>Essen liefern lassen oder abholen</h3>
                      <h2>Lieferzeiten {storeProps.restaurantName}</h2>
                    </div>
                  </Col>
                  <Col md={6} className="d-none d-sm-block">
                    &nbsp;
                  </Col>
                </Row>
              )}
            </Col>
            <OpeningSignCol xs={12} md={6} className={storeProps.onlyPickUp ? 'order-md-2' : ''}>
              <OpeningSignWrapper>
                <Image
                  src={
                    getOverallStatus(storeProps) === 'OPENED'
                      ? '/svg/openingSign.svg'
                      : '/svg/closedSign.svg'
                  }
                  layout="fill"
                  alt={
                    getOverallStatus(storeProps) === 'OPENED'
                      ? 'Restaurant aktuell geöffnet - Öffnungszeiten'
                      : 'Restaurant aktuell geschlossen'
                  }
                />
              </OpeningSignWrapper>
            </OpeningSignCol>
            <OpeningTimesListCol
              xs={12}
              md={6}
              className={storeProps.onlyPickUp ? 'order-md-1' : ''}
            >
              <div>
                <OpeningTimesList
                  openingDaysAndTimesArr={storeProps.openingHours.days}
                  specialOpeningDays={storeProps.specialOpeningDays}
                />
              </div>
            </OpeningTimesListCol>
          </OpeningHoursRow>
        </Container>
      </WhiteBg>

      <GrayBg>
        <Container>
          <OrderWithUsRow>
            <StyledIconsLine />
            <Col xs={12}>
              <OrderWithUsTextWrapper>
                <h2>Essen bestellen bei {storeProps.restaurantName}</h2>
                {storeProps.onlyPickUp ? (
                  <p>
                    Du kannst bei uns über diese Website jederzeit stressfrei von zuhause aus oder
                    von unterwegs, schnell und einfach vorbestellen. Wir geben Dir im Bestellvorgang
                    bescheid, wann dein Essen bei uns abholbereit ist. Spare Zeit und vermeide
                    Missverständnisse bei der telefonischen Bestellung und bestell jetzt einfach
                    online.
                  </p>
                ) : (
                  <p>
                    Wir freuen uns über Deine Bestellung bei uns. Geliefert wird nach Hause, zum
                    Arbeitsplatz oder auch ins Hotel. Eine Abholung Deiner Bestellung ist
                    selbstverständlich auch möglich.
                  </p>
                )}
                <p style={{ marginBottom: '0' }}>
                  Diese Website wird bereitgestellt von FoodAlley. FoodAlley ist eine echte
                  Alternative zu den bisher am Markt bestehenden Anbietern für Liefersysteme und
                  Essenslieferanten. Bei jeder Bestellung unterstützt Du uns, denn dank dem fairen
                  Preismodell von FoodAlley bleibt uns mehr übrig.
                </p>
              </OrderWithUsTextWrapper>
            </Col>
          </OrderWithUsRow>
          <ButtonRow>
            <Col xs={12}>
              <Button
                color="primary"
                size="lg"
                style={{
                  position: 'relative',
                  fontSize: '17px',
                  paddingLeft: 40,
                  paddingRight: 40,
                  letterSpacing: '1.2px',
                  height: 50,
                }}
                onClick={() => {
                  if (storeProps.onlyPickUp) return push('/menu');
                  window.scrollTo({
                    top: zipRef.current.offsetTop,
                    behavior: 'smooth',
                  });
                  zipRef.current.focus({ behavior: 'smooth' });
                }}
              >
                Jetzt bestellen
              </Button>
            </Col>
          </ButtonRow>
        </Container>
      </GrayBg>

      <WhiteBg ref={observe}>
        <Container>
          <MapAndContactRow>
            <ContactCol xs={12} md={6}>
              <h2>Kontaktdaten</h2>

              <ContactDetailComponent
                style={{ marginBottom: '20px' }}
                title={storeProps.restaurantName}
                subtitle={`${storeProps.address.street} ${storeProps.address.houseNumber}, ${storeProps.address.zip} ${storeProps.address.city}`}
                iconName="position"
                iconStyle={{ width: '15px' }}
              />

              <ContactDetailComponent
                style={{ marginBottom: '20px' }}
                title={`${storeProps.emailAddress}`}
                subtitle="Wir freuen uns auf Dein Feedback"
                iconName="e-mail"
                iconStyle={{ width: '25px' }}
              />

              <ContactDetailComponent
                title={`${storeProps.telephoneNumber}`}
                subtitle="Für telefonische Rückfragen"
                iconName="phone"
                iconStyle={{ width: '21px' }}
              />
            </ContactCol>
            <MapCol xs={12} md={6} className="text-center">
              <MapWrapper>
                {inView && <GMapsComponent position={storeProps.gpsLocation} />}
              </MapWrapper>
            </MapCol>
          </MapAndContactRow>
        </Container>
      </WhiteBg>

      <RegionSelectorModal
        show={selectionModalState.show}
        onHide={hideRegionModal}
        onSelection={(selectedRegion) =>
          onRegionSelected(selectedRegion, selectionModalState.forZip, true)
        }
        forZip={selectionModalState.forZip}
        regionsList={selectionModalState.regionList}
        currentDeliveryTime={storeProps.currentDeliveryTime}
      />
    </>
  );
}

export default Home;

Home.getLayout = function PageLayout(page) {
  return (
    <>
      <Header initiallyShown={false} />
      {page}
      <Footer />
    </>
  );
};
