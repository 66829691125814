import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import OpeningTimesListEntry from './OpeningTimesListEntry';

const Wrapper = styled.div`
  padding: 20px 15px;
  text-align: left;
  background-color: #ffffff;
  &:nth-child(odd) {
    background-color: #fafafb;
  }
`;

const SpecialOpeningDaysWrapper = styled.div`
  text-align: left;

  & > h3 {
    align-self: start;
    color: #000000;
    margin: 20px 15px 0px 15px;

    font-size: 15px;
    letter-spacing: 0.67;
    @media (min-width: 768px) {
      font-size: 17px;
      letter-spacing: 0.75;
    }
  }
`;

const dayNamesList = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag',
];

const indexUsToEu = (index) => {
  return index === 6 ? 0 : index + 1;
};
const isToday = (currentIndex) => {
  let usDayOfWeek = indexUsToEu(currentIndex);
  return moment().day() === usDayOfWeek;
};
const dateIsToday = (givenDateAsMoment) => {
  const today = moment().startOf('day');
  return givenDateAsMoment.startOf('day').diff(today) === 0;
};

function OpeningTimesList({ openingDaysAndTimesArr, specialOpeningDays }) {
  return (
    <div>
      {dayNamesList.map((dayName, index) => (
        <Wrapper key={dayName}>
          <OpeningTimesListEntry
            dayName={dayName}
            isToday={isToday(index)}
            openingTimesArr={openingDaysAndTimesArr[indexUsToEu(index)]}
          />
        </Wrapper>
      ))}
      {specialOpeningDays?.length > 0 && (
        <SpecialOpeningDaysWrapper>
          <h3>Abweichende Öffnungszeiten</h3>

          {specialOpeningDays.map((specialOpeningD, index) => (
            <Wrapper key={`${specialOpeningD._id}-${index}`}>
              <OpeningTimesListEntry
                dayName={moment(specialOpeningD.date).locale('de').format('L')}
                isToday={dateIsToday(moment(specialOpeningD.date))}
                isSpecialOpeningDay={true}
                openingTimesArr={specialOpeningD.closed ? [] : specialOpeningD.timeAsEpochMillis}
              />
            </Wrapper>
          ))}
        </SpecialOpeningDaysWrapper>
      )}
    </div>
  );
}

OpeningTimesList.propTypes = {
  openingDaysAndTimesArr: PropTypes.array,
  specialOpeningDays: PropTypes.array,
};

export default OpeningTimesList;
